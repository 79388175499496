import villejuif from "@/assets/images/salons/salon-villejuif.webp";
import augreydescouleurs from "@/assets/images/salons/salon-au-grey-des-couleurs.webp";
import villedavray from "@/assets/images/salons/ville-d-avray.webp";
import choisyleroi from "@/assets/images/salons/choisy-le-roi.jpg";

interface OurSalonsImagesData {
  id: string;
  name: string;
  imagePath: string;
  address: string;
  postalCode: string;
  city: string;
}

export const ourSalonsImagesData: OurSalonsImagesData[] = [
  {
    id: "choisy-le-roi",
    name: "Salon de Coiffure Choisy-le-Roi",
    imagePath: choisyleroi.src,
    address: "2 Place de l'Église",
    postalCode: "94600",
    city: "Choisy-le-Roi",
  },
  {
    id: "villejuif",
    name: "Salon de Coiffure Villejuif",
    imagePath: villejuif.src,
    address: "61 Rue Jean Jaurès",
    postalCode: "94800",
    city: "Villejuif",
  },
  {
    id: "au-grey-des-couleurs",
    name: "Au Grey des Couleurs Villejuif",
    imagePath: augreydescouleurs.src,
    address: "1 Rue Eugène Varlin",
    postalCode: "94800",
    city: "Villejuif",
  },
  {
    id: "ville-d-avray",
    name: "Salon de Coiffure Ville d'Avray",
    imagePath: villedavray.src,
    address: "28 Rue de Saint-Cloud",
    postalCode: "92410",
    city: "Ville-d'Avray",
  },
];
